<template>
  <div class="bg-white scroll">
    <div class="flex justify-center mt-2">
      <svg
        width="68"
        height="68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2.5"
          y="2.5"
          width="63"
          height="63"
          rx="9.5"
          fill="#fff"
          stroke="#fff"
          stroke-width="5"
          stroke-linejoin="round"
        ></rect>
        <path
          d="M63 57.322v-46.66a5.67 5.67 0 00-5.68-5.66H10.68A5.67 5.67 0 005 10.662v46.675a5.67 5.67 0 005.673 5.65c11.148.015 35.567 0 46.651-.015A5.67 5.67 0 0063 57.322z"
          fill="#E3051B"
        ></path>
        <path
          d="M38.888 36.014v-4.016h6.45v-6.883h4.122v6.878h6.444v4.016H49.46v6.878H45.34v-6.878l-6.451.005zM19.762 20.436l6.55 16.52 6.586-16.52h7.666L29.12 47.573h-5.615L12.096 20.436h7.666z"
          fill="#fff"
        ></path>
      </svg>
    </div>
    <div class="p-2">
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.scroll:-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
}
.scroll {
  overflow-x: hidden;
}
</style>
